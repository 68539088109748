/* Common Section CSS */

/*The title of each section */
.header {
  font-family: "Gilroy";
  font-size: 35pt;
  text-decoration: overline;
  margin-top: 0px;
}

/* The block that defines the section itself. */
.block {
  min-height: 100vh;
  min-width: 100vw;
}

/*The surrounding content inside of a block*/
.section {
  padding: 7%;
  overflow:auto;
}

/******** Title ********/

.title-page {
  background-color: #1f1e1d;
  padding-top: 20%;
  text-align: center;
}

#name {
  font-family: "Pavanam";
  font-size: 3em;
  color: white;
  margin: 0px 0px 0px 0px;
}

#description {
  font-family: "Pavanam";
  font-size: 2.5em;
  color: white;
  margin: 1% 0px 20px 0px;
}

#quote {
  font-family: "Pavanam";
  font-size: 1.5em;
  color: white;
  text-align: center;
  margin-bottom: 3%;
}

.begin-box {
  color: white;
  padding: 0.6%;
  text-align: center;
  box-sizing: border-box;
  border: 0.1em solid white;
  display: inline;
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
}

.begin-box:hover {
  cursor: pointer;

  background-color: #a1a09d;
}

.arrow:hover {
  cursor: pointer;
}

/******** About ********/
.about {
  background-color: #4d4f6b;
  color: white;
  min-width: 100vw;
}

#about {
  padding: 0px 20% 0px 20%;
  font-family: "FFMeta";
  font-size: 1.3em;
  color: white;
  margin-bottom: 0px;
}

/******** Experience ********/
.experience {
  background-color: #7a3d46;
  color: white;
}

/******** Project ********/

.projects {
  background-color: #293557;
  color: white;
  height: 100%;
  width: 100%;
}

.project-container {
  text-align: center;
  height: auto;
  width: auto;
}

.project-preview {
  margin: 0;
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: bottom;
  margin: 1%;
}


.project-preview img {
  position: relative;
  display: block;
  max-height: 100%;
  max-width: 100%;
}

.project-preview .text-box {
 color: white;
 font-size: 10pt;
 position: absolute;
 top: 0;
 height: 100%;
 width: 100%;
 opacity: 0;
 background-color: rgba(0,0,0,0.9);
 transition: .3s ease;
}

.project-preview .title {
  font-family: 'FFMeta';
  font-size: 15pt;
  font-weight: bold;
  text-align: center;
  margin-top: 8%;
}

.project-preview .description {
  font-family: 'FFMeta';
  font-size: 10pt;
  margin-top: 100px;
  padding: 0px 10% 0px 10%;
  -webkit-transition: 0.3s ease;
}

.project-preview:hover .text-box {
 opacity: 1;
 background-color: rgba(0,0,0,0.75);
}

.project-preview:hover .description {
  margin-top: 0px;
}

.project-preview .buttons {
  margin-bottom: 30px;
}

.project-preview .button {
  margin-right: 10px;
}

.project-preview .description {
  text-align: center;
  font-family: "FFMeta";
}

/* Portfolio */

.project-preview .button {
  border-width: thin;
  border-style: solid;
  border-color: white;
  background-color: rgb(0,0,0,0);
  color: white;
  padding: 5px 5px 5px 5px;
  font-weight: 500;
}

.project-preview .tag {
  border-radius: 10px;
  background-color: #1c2d45;
  color: white;
  padding: 5px 5px 5px 5px;
  font-weight: 500;
}

.project-preview a:link {
  text-decoration: none;
}

.project-preview a:visited {
  text-decoration: none;
}


/******** Contact ********/

.contact {
  background-color: #2e3036;
  color: white;
}

.contact-details {
  font-size: 20pt;
}

.contact-details img {
  vertical-align: middle;
  margin-right: 1%;
}

.contact-details a:link {
  color: white;
  text-decoration: none;
}

.contact-details a:visited {
  color: white;
  text-decoration: none;
}

/* Font */
@font-face {
    font-family: 'FFDin';
    src: url('fonts/ff_din_normal.ttf');
}

@font-face {
    font-family: 'FFMeta';
    src: url('fonts/ff_meta_normal.ttf');
}

@font-face {
    font-family: 'Gilroy';
    src: url('fonts/gilroy_light.otf');
}