/******** Navbar css ********/

.navbar ul {
  font-size: 1em;
  list-style-type: none;
  background-color: #2a2b38;
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  width: 100%;
  padding-left: 7%;
  z-index: 1;
}

.underbar {
  width: 0px;
  height: 5px;
  background: rgba(100,100,200,0);
  left: 0vw;
  top: 2.7rem;
  position:inherit;
  -webkit-transition: 0.5s ease;
}

.navbar li {
  float: left;
  -webkit-transition: 0.5s ease;
  cursor: pointer;
}

  .navbar li:nth-of-type(1):hover ~ .underbar {
    left: 7.5vw;
    width: 2rem;
    height: 0.2rem;
    background: rgba(187,223,252,0.8);
    box-shadow: 0 0 10px rgba(222,225,233,1);
  }

  .navbar li:nth-of-type(2):hover ~ .underbar {
    left: 12.1vw;
    width: 2rem;
    height: 0.2rem;
    background: rgba(199, 82, 82,0.8);
    box-shadow: 0 0 10px rgba(242, 7, 30,1);
  }

  .navbar li:nth-of-type(3):hover ~ .underbar {
    left: 17.5vw;
    width: 50px;
    height: 3px;
    background: rgba(150,250,250,0.5);
    box-shadow: 0 0 10px rgba(70,250,250,1);
  }

  .navbar li:nth-of-type(4):hover ~ .underbar {
    left: 24.4vw;
    width: 50px;
    height: 3px;
    background: rgba(114, 207, 131,0.8);
    box-shadow: 0 0 10px rgba(89, 255, 120,1);
  }

  .navbar li:nth-of-type(5):hover ~ .underbar {
    left: 30.9vw;
    width: 40px;
    height: 3px;
    background: rgba(252,187,208,0.8);
    box-shadow: 0 0 10px rgba(255,227,236,1);
  }

  .navbar li:nth-of-type(6):hover ~ .underbar {
    left: 36.5vw;
    width: 40px;
    height: 3px;
    background: rgba(237,127,9,0.8);
    box-shadow: 0 0 10px rgba(255,132,0,1);
  }

.navbar li a {
  display: block;
  padding: 20px 40px 20px 0px;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  color: white;
}